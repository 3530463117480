<template>
  <div class="wrapper padding-lr-sm">
    <el-form :model="orgForm"
             label-width="120px"
             :rules="rules" ref="orgForm" class="add-form">
      <el-form-item label="公司名称" prop="mingcheng">
        <el-input v-model="orgForm.mingcheng" placeholder="请填写公司名称"></el-input>
      </el-form-item>
      <el-form-item label="机构性质" prop="jigouxingzhi">
        <el-input v-model="orgForm.jigouxingzhi" placeholder="请填写机构性质"></el-input>
      </el-form-item>
      <el-form-item label="法定代表人" prop="faren">
        <el-input v-model="orgForm.faren" placeholder="请填写法定代表人"></el-input>
      </el-form-item>

      <el-form-item label="公司联系人" prop="lianxiren">
        <el-input v-model="orgForm.lianxiren" placeholder="请填写联系人"></el-input>
      </el-form-item>
      <el-form-item label="公司电话" prop="dianhua">
        <el-input v-model="orgForm.dianhua" placeholder="请填写电话"></el-input>
      </el-form-item>
      <el-form-item label="公司邮箱" prop="youxiang">
        <el-input v-model="orgForm.youxiang" placeholder="请填写邮箱"></el-input>
      </el-form-item>
      <el-form-item label="公司名地址" prop="dizhi">
        <el-input v-model="orgForm.dizhi" placeholder="请填写地址"></el-input>
      </el-form-item>
      <el-form-item label-width="0" class="flex justify-around">
        <el-button class="submit-btn" type="primary" @click="submitForm('orgForm')">保存</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {addOrEditOrg, orgShow} from "@/api/org";

export default {
  name: "OrgList",
  data() {
    return {
      orgForm: {
        mingcheng: '',
        jigouxingzhi: '',
        faren: '',
        lianxiren: '',
        dianhua: '',
        youxiang: '',
        dizhi: '',
      },
      rules: {
        mingcheng: rules.mustInput,
        jigouxingzhi: rules.mustInput,
        faren: rules.mustInput,
        lianxiren: rules.mustInput,
        dianhua: rules.phone,
        youxiang: rules.email,
        dizhi: rules.mustInput,
      },
    }
  },
  created() {
    this.$emit("setIndex", this.$route.path, "公司信息", false);
    this.orgShow()
  },
  components: {},
  methods: {
    async orgShow() {
      const res = await orgShow(this.$store.state.orgId)
      this.orgForm = {
        mingcheng: res.data.mingcheng,
        jigouxingzhi: res.data.jigouxingzhi,
        faren: res.data.faren,
        lianxiren: res.data.lianxiren,
        dianhua: res.data.dianhua,
        youxiang: res.data.youxiang,
        dizhi: res.data.dizhi
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      let _state = await addOrEditOrg(
        this.$store.state.orgId,
        this.orgForm.mingcheng,
        this.orgForm.jigouxingzhi,
        this.orgForm.faren,
        this.orgForm.lianxiren,
        this.orgForm.dianhua,
        this.orgForm.youxiang,
        this.orgForm.dizhi,
      )

      if (_state.status === 200) {
        this.$message.success(_state.info)
      } else {
        this.$message.error(_state.info)
      }
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">

</style>
